import logo from "./logo.svg";
import "./App.css";
import { Dashboard } from "./components/Dashboard";
function App() {
	return (
		<div className="App">
			<Dashboard />
		</div>
	);
}

export default App;
