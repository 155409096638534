import React from "react";
import "./keysnippets.css";

export const Keysnippets = () => {
	return (
		<div className="keycontainer">
			<div className="kl">
				<span>1</span>
			</div>
			<div className="km">
				Lorem ipsum dolor sit ametemporibus consectetuluta omnis ut, commodi
				dolor, mag
			</div>
			<div className="kr">General</div>
		</div>
	);
};
